<template>
  <b-card>
    <div style="text-align: end;">
      <b-button
          v-if="model.file"
          class="mr-1"
          size="sm"
          variant="success"
          type="button"
          @click.prevent="printContract"
        >
          <feather-icon icon="PrinterIcon" /> 
          <span> Print Kontrak</span>
      </b-button>
    </div>
    <base-info
      ref="baseinfo"
      :geturl="dataurl"
      :fields="fields"
      :baseroute="baseroute"
      @readData="showData"
    >
    </base-info>
  </b-card>
</template>

<script>

import {BCard, BButton} from 'bootstrap-vue'
import BaseInfo from '@/views/base/BaseInfo.vue'

export default {
  components: {
    BCard,
    BaseInfo,
    BButton
  },

  data() {
    return {
      model: {
        file: null
      },
      dataurl:"/contract",
      baseroute:"contract",
      loading: false,
      fields: [
        { key: 'number', label: 'Nomor Kontrak', bold:true},
        { key: 'subject', label: 'Nama Kontrak' },
        { key: 'vendor.name', label: 'Vendor'},
        { key: 'date', label: 'Tanggal Kontrak'},
      ]
    }
  },
  methods: {
    printContract() {
      if (this.model)
        window.open(this.model.file_url)
    },
    showData(data){
      this.model = data
    },
  },
}
</script>
